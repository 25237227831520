@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  background: #fff;
  border-radius: 2rem;
  margin: 2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background:url(./assets/bgp.jpg);
  background-position: center;
  background-size:cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 2rem;
  font-family: 'Roboto Mono', monospace;
  font-weight:500;
  /* background: #fff; */
  border-radius: 1rem;
  margin-top: 5rem;
  padding: .5rem 1rem;
  cursor: pointer;
  transition: all 0.5s;
}
p:hover {
  font-size: 2.1rem;
}
.social_media {
  display: flex;
}
.social_media a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;height: 4rem;
  margin: 1rem;
  font-size: 2rem;
  color: #333;
  /* background: #fff; */
  border-radius: 50%;
  transition: all 0.3s;
}

.social_media .facebook:hover {
  font-size: 2.6rem;
  color: #1877f2;
}       
.social_media .twitter:hover{
  font-size: 2.6rem;
  color: #55acee;
}
.social_media .instagram:hover{
  font-size: 2.6rem;
  color: #ff3040;
}
.social_media .linkedin:hover{
  font-size: 2.6rem;
  color: #0a66c2;
}


/* Loader */
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18rem;
  height: 18rem;
  /* padding: 5rem; */
  border-radius: 50%;
  /* background:#fff; */
  animation: load-pulse 2s infinite linear;
}
.item .loader-pulse{
  width: 20rem;
  height: auto;
}
@keyframes load-pulse {
  0% {
    opacity: 0.15;
  }
  15% {
    opacity: 0.3;
  }
  30% {
    opacity: 0.45;
  }
  45% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.9;
  }
  90% {
    opacity: 0.95;
  }

  100% {
    transform: scale(0.9);
    opacity: 1;
  }
}
/* Loader */

.App-header .mail {
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.5s;
}
.App-header .mail:hover {
  border-bottom: 1px solid #333;
  font-size: 1.5rem;
}